<template>
  <app-layout class="bg-white">
    <div class="container mx-auto">
      <div class="m-5 flex flex-row justify-between items-center">
        <span class="text-xl">{{ $t("business.main.business_list") }}</span>
        <app-button
          width="w-20 lg:w-1/6"
          :showf70Icon="false"
          @click="create_new_business"
        >
          {{ $t("business.main.create_new_business") }}
        </app-button>
      </div>
    </div>
    <hr class="w-full border-b border-gray-200" />
    <div class="container mx-auto">
      <app-table
        :tabs="tabs"
        :apiResponse="list_business"
        :showEmptyButton="false"
        :showNumber="false"
        @params-changed="params_changed"
        @tab-changed="tab_changed"
        :loading="apiPaginationLoading"
        @on-item-click="show_detail"
        :filters="[
          {
            key: 'filter[id]',
            type: 'select',
            placeholder: $t('business.table.header_businss_name'),
            items: all_business,
          },
          {
            key: 'filter[submitted_at_between]',
            type: 'date',
            placeholder: 'Created At',
            value: '',
          },
          {
            key: 'filter[type_of_business_id]',
            type: 'select',
            placeholder: 'Business Type',
            items: typeOfBusinesses,
          },
          {
            key: 'filter[detail.industry_id]',
            type: 'select',
            placeholder: 'Industry',
            items: industries,
          },
          {
            key: 'filter[country_id]',
            type: 'select',
            placeholder: 'Country',
            items: countries,
          },
        ]"
      >
        <template v-slot:header>
          <td>
            <b>{{ $t("business.table.header_businss_name") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_businss_type") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_industry") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_country") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_submitted_date") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_status") }}</b>
          </td>
        </template>
        <template v-slot:body="data">
          <td>
            <p>
              {{ data.model?.name }}
            </p>
          </td>
          <td>
            <p>{{ data.model?.type_of_business?.description ?? "-" }}</p>
          </td>
          <td>
            <p>{{ data.model?.detail?.industry?.name ?? "-" }}</p>
          </td>
          <td>
            <p>{{ data.model?.country?.name ?? "-" }}</p>
          </td>
          <td>
            <p>
              {{
                data.model?.submitted_at
                  ? $moment(data.model.submitted_at).format("DD MMMM YYYY")
                  : "-"
              }}
            </p>
          </td>
          <td>
            <app-badge :status="getStatus(data.model?.business_status_id)">
              {{ data.model?.status?.name ?? "-" }}
            </app-badge>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  data() {
    return {
      tabs: [],
      current_tab: null,
      queryParams: "",
    };
  },
  created() {
    this.fetchListBusiness();
  },
  computed: {
    list_business() {
      return this.$store.getters["masterBusinessStore/list_business"];
    },
    all_business() {
      return this.$store.getters["masterBusinessStore/all_business"];
    },
    apiPaginationLoading() {
      return this.$store.getters["masterBusinessStore/apiPaginationLoading"];
    },
    typeOfBusinesses() {
      return this.$store.getters["referenceStore/typeOfBusinesses"];
    },
    industries() {
      return this.$store.getters["referenceStore/industries"];
    },
    countries() {
      return this.$store.getters["referenceStore/countries"];
    },
  },
  mounted() {
    this.fetchListBusiness();

    this.$store.dispatch("referenceStore/getTypeOfBusinesses", 0);
    this.$store.dispatch("referenceStore/getIndustries", 0);
    this.$store.dispatch("referenceStore/getCountries", 0);

    this.tabs = [
      { key: "all", title: this.$t("business.table.tab_all") },
      { key: "created", title: this.$t("business.table.tab_created") },
      { key: "review", title: this.$t("business.table.tab_to_review") },
      { key: "approved", title: this.$t("business.table.tab_approved") },
      { key: "amended", title: this.$t("business.table.tab_amended") },
      { key: "rejected", title: this.$t("business.table.tab_rejected") },
    ];

    this.$store.dispatch("masterBusinessStore/getMasterBusiness");

  },
  methods: {
    getStatus(status_id) {
      switch (status_id) {
        case 1:
          return "light";
        case 2:
          return "light";
        case 3:
          return "success";
        case 4:
          return "error";
        case 5:
          return "warning";
        default:
          return "light";
      }
    },
    create_new_business() {
      this.$store.commit("masterBusinessStore/clearBusiness");
      window.location.href = "/master-business/business";
    },
    show_detail(business) {
      this.$store.commit("masterBusinessStore/setBusiness", business);
      this.$router.push({
        path: "/master-business/business/structure",
        query: {
          business_id: business.id,
        },
      });
    },
    params_changed(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListBusiness();
    },
    tab_changed(tab_name) {
      this.current_tab = tab_name;
    },
    fetchListBusiness() {
      this.getTabStatusTab();
      this.$store.dispatch(
        "masterBusinessStore/getListMasterBusiness",
        this.queryParams
      );
    },
    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == "created") {
        status_tab = BUSINESS_STATUS.CREATED;
      } else if (currentTab == "review") {
        status_tab = BUSINESS_STATUS.SUBMITTED;
      } else if (currentTab == "approved") {
        status_tab = BUSINESS_STATUS.APPROVED;
      } else if (currentTab == "amended") {
        status_tab = BUSINESS_STATUS.AMENDED;
      } else if (currentTab == "rejected") {
        status_tab = BUSINESS_STATUS.REJECTED;
      } else {
        status_tab = [
          BUSINESS_STATUS.CREATED,
          BUSINESS_STATUS.APPROVED,
          BUSINESS_STATUS.SUBMITTED,
          BUSINESS_STATUS.AMENDED,
          BUSINESS_STATUS.REJECTED,
        ].join(",");
      }

      this.queryParams = (this.queryParams == "") ? 
        "?filter[business_status_id]=" + status_tab :
        this.queryParams + "&filter[business_status_id]=" + status_tab;
    },
  },
};
</script>
